.goalLine {
  display: flex;
  flex-direction: column;
  width: 100%;

  .goalLine-title {
    align-self: center;
    color: white;
    text-shadow: black 1px 0 5px;
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: 500;
    font-size: 30px;
    margin-left: -5%;
  }

  .goalLine-lineBox {
    display: flex;
    flex-direction: row;

    .goalLine-subscribeBanner {
      height: 63px;
      max-height: 63px;
      width: 366px;
      max-width: 366px;
      background-color: #F9D272;
      background-image: url(../../../../../public/subscribe-banner.gif);
    }

    .goalLine-line {
      display: flex;
      flex-direction: row;
      height: 63px;
      max-height: 63px;
      background-color: rgb(0, 0, 0);
      background-image: url(../../../../../public/goal-image.png);
      flex: 1;
      position: relative;

      .goalLine-currentIndicator {
        background: rgba(255, 255, 255, 0.25);
        transition: all 400ms ease-out;
      }

      .goalLine-sums {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        position: absolute;
        margin-top: 16px;
        padding-left: 10px;
        padding-right: 10px;
        box-sizing: border-box;

        .goalLine-sum {
          color: white;
          text-shadow: black 1px 0 5px;
          font-size: 26px;
          margin-top: 0px;
          margin-bottom: 0px;
          font-weight: 500;
          font-family: 'Rubik';
        }
        
        .goalLine-sum_current {
          font-size: 28px;
          font-weight: 900;
          margin-left: -100px;

          h3 {
            position: absolute;
            margin-top: -21px;
          }
        }

        .goalLine-sum_title {
          font-size: 28px;
          font-weight: 400;
          text-shadow: black 1px 0 5px;
          margin-left: -100px;
        }
      }
    }
  }
}
.admin {
  display: flex;
  justify-content: center;
  background: #141414;
  color: #FFFFFF; 
  min-height: 100vh;
}

.css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
  background-color: #202222 !important;
  color: gainsboro !important;
}
.sortingTools {
  margin-top: -14px;
  margin-bottom: 15px;
  margin-right: 15px;
  margin-left: 4px;
  border-radius: 4px;
  display: flex;
  justify-content: center;

  .sortingTools_sortButtons {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 36px;

    .sortingTools-button {
      display: block;
      padding: 0px 10px;
      height: 70%;
      border: 0;
      user-select: none;
      -moz-user-select: none;
      -ms-user-select: none; 
      -o-user-select: none; 
      -webkit-user-select: none;
      cursor: pointer;
      margin-right: 6px;
      border-radius: 6px;
      background: none;
      border: 1px solid #413e3e;
      color: #413e3e;
    }
    
    .sortingTools-button:active, .sortingTools-button:focus {
      outline: none;
    }

    .sortingTools-button_active {
      background: #413e3e;
      color: black;
    }
  }

  .sortingTools_searchButtonBox {
    .sortingTools_searchButton {
      margin-left: 3px;
      opacity: .4;
    }
  }

  .sortingTools_search {
    display: flex;
    margin-top: -4px;

    .sortingTools_searchFieldBox {
      .sortingTools_searchField {
        div {
          color: gainsboro !important;
          opacity: .9;
        }

        fieldset {
          border-color: gray !important;
        }

        .sortingTools_searchFieldIcon {
          opacity: .6;
        }

        hr {
          border-color: gray !important;
          opacity: .5;
        }

        .sortingTools_searchStartButton {
          margin-left: 5px;
          margin-right: -7px;
        }
      }
    }

    .sortingTools_searchCloseButtonBox {
      padding-top: 3px;
      margin-left: 6px;
    }
  }
}

@media (max-width: 768px) {
  .sortingTools_sortButtons {
    height: 60px !important;
  }

  .sortingTools_searchButtonBox {
    padding-top: 12px;
  }

  .sortingTools {
    margin-right: 6px;
    margin-left: 6px;
    margin-top: -20px;
    margin-bottom: 7px;
  }

  .sortingTools .sortingTools_search {
    margin-top: 12px;
    margin-bottom: 8px;
  }

  .donationsBlock_archive {
    .donationsCol {
      .sortingTools {
        .sortingTools_sortButtons {
          .sortingTools-button {
            max-width: 75px;
          }
        }
      }
    }
  }
}
.donationsBlock {
  display: flex;
  flex-direction: row;
  min-width: 1280px;
  max-width: 1400px;

  .donationsCol {
    flex: 1;
    min-width: 420px;
    max-width: 550px;
  
    .donationsCol-title {
      font-size: 24px;
      color: gainsboro;
      font-weight: 500;
      text-align: center;

      .donationsCol-button {
        margin-left: 4px;
        margin-top: -5px;
      }
    }

    .donationsCol-title_archived {
      margin-left: -15px;
    }

    .donationsCol-searhResultTitle {
      font-size: 22px;
      color: gainsboro;
      font-weight: 500;
      text-align: center;
      margin-left: -2px;
    }

    .donationsCol-searhNotFoundTitle {
      font-style: italic;
      color: grey;
      text-align: center;
      margin-left: -2px;
    }
  }

  .donationsCol_first {
    margin-left: 55px;
    margin-right: 5px;
  }

  .donationsAlert {
    .MuiPaper-root {
      background: #45702e !important;
      opacity: 1;
    }
  }

  .donationBlock-arrows {
    display: none;
  }
}

.donationsBlock_archive {
  justify-content: center;

  .donationBlock-arrows {
    visibility: hidden;
  }
}


@media (max-width: 768px) {
  .donationsBlock {
    min-width: unset;
    max-width: unset;
    width: 95%;
    justify-content: center;

    .donationsCol {
      flex: 1;
      min-width: unset;
      max-width: 100%;
    }

    .donationsCol_first {
      margin-left: 0;
      margin-right: 0;
    }

    .donationBlock-arrows {
      display: flex;
      position: absolute;
      top: 63px;
      width: 93%;
      justify-content: space-between;

      > * {
        margin-left: -7px;
        margin-right: -1px;
      }
    }
  }

  .donationsBlock_archive {
    justify-content: unset;

    .donationBlock-arrows {
      visibility: hidden;
    }
  }
}

.donationSort {
  min-height: 30px;
  background: grey;
}
.donationAlert {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  min-height: 600px;
  min-width: 600px;
  margin-left: 55px;
  width: fit-content;

  .animation {
    margin-bottom: -20px;
  }

  .message {
    .message-author {
      font-family: 'Rubik';
      font-weight: 500;
      font-size: 24px;
      color: #FFFFC0;
      margin: 0;
      text-align: center;
    }

    .message-sum {
      font-family: 'Rubik';
      font-weight: 500;
      font-size: 35px;
      color: #FFFFC0;
      margin: 0;
      margin-top: 2px;
      text-align: center;
    }
  }
}

.donationWidget-progress {
  display: flex;
  width: 100%;
  height: 350px;
  align-items: center;
  justify-content: center;
}
.settingsBlock {
  margin-top: 0px;
  display: flex;
  flex-direction: row;
  min-width: 1280px;
  max-width: 1400px;
  justify-content: space-around;

 .settingsSection {
  display: flex;
  min-width: 425px;
  max-width: 500px;
  flex-direction: column;
  flex: 1;

    .settingsSection-title {
      font-size: 24px;
      color: gainsboro;
      font-weight: 500;
      text-align: center;

      .settingsSection-addButton {
        margin-left: 4px;
        margin-top: -5px;
      }
    }

    .settingsSection-createButton {
      margin-top: 5px;
      margin-bottom: 10px;
      display: flex;
      flex-direction: column;
      margin-right: 15px;
      margin-left: 23px;
    }

    .settingsSection-items {
      &:empty {
        margin-top: -5px;
      }

      .settingsItem {
        background: #302e2e;
        border-radius: 10px;
        padding-left: 20px;
        padding-right: 15px;
        padding-top: 5px;
        padding-bottom: 10px;
        margin-bottom: 15px;
        margin-left: 7px;
        margin-right: 7px;

        .settingsItem-info {
          display: flex;

          .settingsItem-labels {
            margin-right: 6px;
            color: grey;
          }

          .settingsItem-values {
            color: gainsboro;
          }

          .settingsItem-linkButtons {
            display: flex;
            flex-grow: 1;
            justify-content: flex-start;
            align-items: flex-end;
            padding-top: 9px;
            margin-right: -3px;
            margin-left: 8px;
            flex-direction: column;
            opacity: 0.8;

            > * {
              margin-bottom: 3px;
            }
          }
        }

        .settingsItem-removeButton {
          display: flex;
          flex-direction: column;
          width: 100%;
          margin-bottom: 10px;
        }

        .settingsItem-editButton {
          display: flex;
          flex-direction: column;
          width: 100%;
          margin-bottom: 10px;
        }
      }
    }
 } 
}

@media (max-width: 1000px) {
  .settingsBlock {
    flex-direction: column;
    min-width: unset;
    max-width: unset;

    .settingsSection {
      min-width: 300px;

      .settingsSection-items {
        .settingsItem {
          margin-left: 15px;
          margin-right: 15px;
        }
      }
    }
  }
}
.donation {
  background: #302e2e;
  border-radius: 10px;
  margin-bottom: 15px;
  padding-left: 20px;
  padding-right: 15px;
  padding-top: 20px;
  padding-bottom: 10px;
  margin-left: 7px;
  margin-right: 7px;

  &_warn {
    border: 3px dotted #f7931a80;
  }

  &_read {
    background: #212e21;
  }
  
  .donation-title {
    display: flex;

    .donation-author {
      margin-right: 10px;
      font-size: 20px;
      margin-top: 0;
      overflow-wrap: anywhere;

      .donation-warnIcon {
        margin-bottom: -5px;
        margin-left: -2px;
        margin-right: 6px;
      }
    }

    .donation-amount {
      margin-top: -1px;
    }
  }


  .donation-date {
    margin: 0;
    padding: 0;
    text-align: left;
    margin-top: -18px;
    color: #808080;
    font-size: 14px;
  }  

  .donation-text {
    font-weight: 400;
    overflow-wrap: anywhere;
  }

  .donation-buttons {
    display: flex;
    flex-direction: column;

    > * {
      margin-bottom: 8px;
    }
  }
}

@media (max-width: 1000px) {
  .donation {
    margin-left: 5px;
    margin-right: 15px;
  }
}
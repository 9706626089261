.mainPage {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #000;
    padding-top: 10px;
    padding-bottom: 11px;
    margin-bottom: 5px;
    width: 100%;

    .header-buttons {
      width: 300px;
      display: flex;
      justify-content: space-between;
      margin-left: -15px;

      .header-button {
        background: none;
        color: #939393;
        outline: none;
        border: none;
        cursor: pointer;
        font-size: 14px;
        margin-right: 20px;
      }

      .header-button:last-child {
        margin-right: 0;
      }
  
      .header-button_active {
        color: #ffbb42;
      }
    }
  }
}

@media (max-width: 768px) {
  .mainPage {
    .header {
      .header-buttons {
        margin-left: 0;
        width: 275px;

        .header-button {
          margin-right: 0;
        }
  
        .header-button_tokens {
          display: none;
        }
      }
    }
  }
}